<template>
  <div class="upload">
    <div class="image-input">
      <el-input v-model="imagesUrl" @input="blurFn"></el-input>
      <el-upload
          class="avatar-uploader"
          ref="importUpload"
          :show-file-list="false"
          :http-request="startUpload"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :limit="2"
          :multiple="multiple?true:false"
          :on-change="change"
          :file-list="fileList"
          action="#">

        <el-button type="warning" v-if="!isUpload">上传</el-button>
        <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
      </el-upload>
    </div>
    <!-- 多选 -->
    <div  class="upload-top" v-if="imagesUrl && imagesUrl.indexOf(',')!=-1" style="display: flex;flex-wrap:wrap;">
      <div v-for="(item,index) in imagesUrl.split(',')" :key="index">
        <el-image
            :src="setImages(item)" class="avatar"
            :preview-src-list="imagesUrl.split(',')">
        </el-image>
        <el-button  size="small" type="danger" @click="clearImage(item)">删除</el-button>
      </div>
     </div>
    <!-- 单张 -->
    <div  class="upload-top" v-if="imagesUrl && imagesUrl.indexOf(',')==-1" style="display: flex;flex-direction: column;">
      <el-image
          :src="setImages(imagesUrl)" class="avatar"
          style="width: 20%"
          :preview-src-list="[imagesUrl]">
      </el-image>
      <el-button size="small" type="danger" @click="clearImage">删除</el-button>
     </div>
  </div>

</template>

<script>
import API from "@/api";

export default {
  name: "uploadImage",
  props:['objKey','imageUrl','multiple','isUpload'],
  data(){
    return{
      imagesUrl:'',
      fileList:[]
    }
  },
  watch:{
    imageUrl:{
      handler(data){
        if(data){
          this.imagesUrl = data
          console.log(this.imagesUrl)
        }
      },
      deep:true,
      immediate:true
    }
  },
  methods:{
    setImages(val){
      // 判断是否拥有域名 如果有 去掉域名 字符串第一位是'/'
      if(val.indexOf('http') === 0){
        val = '/' + val.split('/').slice(3).join('/')
      }else if(val.indexOf('/')!==0){
        val = '/' + val
      }
      // 根据去掉域名后的数据进行匹配
      let strValue = ""
      let data = JSON.parse(JSON.parse(localStorage.getItem('imageUrl')))
      for (const key in data) {
        if(key.indexOf('/')!==0 && key !=='*'){//如果匹配键名前面不带'/'
          if(val.indexOf('/'+key+'/')===0){//
            strValue = data[key]
          }
        }else if(key.indexOf('/')===0 && key !=='*'){//带'/'
          if(val.indexOf(key+'/')===0){//有匹配的
            strValue = data[key]
          }
        }
      }
      if(strValue){
        return strValue+val
      }else{
        return data["*"]+val
      }
    },
    change(e,fileList){
      // 如果是多选--图集
      if(this.multiple){
        this.fileList = fileList
      }else{
        if(fileList.length>0){
          this.fileList = [fileList[fileList.length-1]]
        }
      }
      
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async startUpload(file) {
      const form = new FormData();
      form.append('upload', file.file);
      const { data } = await API.fileUpload(form); 
      console.log(this.objKey,data.coverImg,this.multiple)
      this.$emit('uploadFileUrl',this.objKey,data.coverImg,this.multiple)
    },
    clearImage(data){
      if(this.multiple){
        let list = this.imagesUrl.split(',');
        list.forEach((v,i)=>{
          if(v==data){
            list.splice(i,1)
          }
        })
        if(list.length){
          this.$emit('clearImage',this.objKey,list.join(','),this.multiple)
        }else{
          this.$emit('clearImage',this.objKey)
          this.fileList = []
          this.imagesUrl = ''
        }
      }else{
        this.$emit('clearImage',this.objKey)
        this.fileList = []
        this.imagesUrl = ''
      }
      
    },
    blurFn(){
      this.$emit('validate',this.objKey,this.imagesUrl)
    }
  }
}
</script>

<style scoped lang="scss">
.upload{
  display: flex;
  flex-direction: column !important;
  
  .upload-top {
    >div{
      width: 20%;
      margin-right: 5px;
      .el-button{
        width: 100%;
      }
    }
    .el-button{
      width: 20%;
    } 
  }
}
.image-input{
  display: flex;
}
</style>
